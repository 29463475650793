import React from 'react'

const Errorpage = () => {
  return (
    <div>
        <h1>Sorry no data available</h1>
      
    </div>
  )
}

export default Errorpage
